import styled from "styled-components"

import { CircularProgress } from "@material-ui/core"

import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const CHART_HEIGHT = 450 //px

export function GraphCard({
  title,
  info,
  isLoading,
  children,
  hideGraphBorder,
  hidden,
  isLegacyGraph,
}: {
  title?: React.ReactNode
  info?: React.ReactNode
  isLoading?: boolean
  children: React.ReactNode
  hideGraphBorder?: boolean
  hidden?: boolean
  isLegacyGraph?: boolean
}) {
  if (hidden) return null

  return (
    <Card $hideGraphBorder={hideGraphBorder}>
      <GraphHeader $hideGraphBorder={hideGraphBorder}>
        <MText variant="subtitle">{title}</MText>
        <MText variant="bodyS" color="secondary">
          {info}
        </MText>
      </GraphHeader>

      <Chart $isLegacyGraph={!!isLegacyGraph}>
        {isLoading ? (
          <LoadingBox>
            <StyledCircularProgress />
          </LoadingBox>
        ) : (
          children
        )}
      </Chart>
    </Card>
  )
}

const Card = styled.div<{ $hideGraphBorder?: boolean }>`
  ${({ $hideGraphBorder: hideGraphBorder }) =>
    !hideGraphBorder && `border: 1px solid ${mColors.divider}`};
  border-radius: ${spacing.L};
`

const GraphHeader = styled.div<{ $hideGraphBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $hideGraphBorder: hideGraphBorder }) =>
    hideGraphBorder
      ? `padding: 0 ${spacing.XL} 0;`
      : `padding: ${spacing.XL} ${spacing.XL} 0;`};
`

const Chart = styled.div<{ $isLegacyGraph: boolean }>`
  padding-right: ${spacing.XL};
  padding-left: ${spacing.XL};
  ${({ $isLegacyGraph }) => {
    if ($isLegacyGraph) {
      return `height: ${CHART_HEIGHT}px;`
    }
    return `padding: ${spacing.L};`
  }}
`

const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const StyledCircularProgress = styled(CircularProgress)`
  margin-right: ${spacing.L};
  color: #3fa5da;
`
