import { useCallback, useMemo } from "react"
import ReactServer from "react-dom/server"
import styled from "styled-components"

import * as Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsBoost from "highcharts/modules/boost"
import HighchartsExportData from "highcharts/modules/export-data"
import HighchartsExport from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"

import { TClockType, TUser } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import {
  getActiveNoiseThreshold,
  ITimeScopedThreshold,
} from "src/ui/Graphs/configurationUtils"
import {
  Box,
  Content,
  DownArrow,
  Header,
  Row,
  UpArrow,
  Value,
} from "src/ui/Graphs/LineGraph/DataPointTooltip"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

HighchartsExport(Highcharts)
HighchartsExportData(Highcharts)
HighchartsMore(Highcharts)
HighchartsBoost(Highcharts)

type TData = {
  value: number | undefined
  min?: number
  max?: number
  datetimeRaw: string | Date
}

export type LineChartProps = {
  data: TData[]
  tooltip: {
    unit: string
    decimals?: number
    formatter?: (context: {
      date: Date
      value: number
      min?: number
      max?: number
    }) => React.ReactElement
  }
  canExport?: boolean
  thresholds?: ITimeScopedThreshold[]
  yPlotLines?: number[]
  onZoom?: (startDate: Date, endDate: Date) => void
  onZoomReset?: () => void
  smooth?: boolean
  zoom?: "x" | "xy" | "y"
  timezone: string
  clockType: TClockType
  yAxisOptions?: Highcharts.YAxisOptions
}

export function LineChart({
  data,
  thresholds,
  yPlotLines,
  tooltip,
  onZoom,
  onZoomReset,
  smooth = true,
  canExport = false,
  zoom = "x",
  timezone,
  clockType,
  yAxisOptions,
}: LineChartProps) {
  const { t } = useTranslate()
  const minMaxIncluded = !!data[0]?.max && !!data[0]?.min

  const series = useMemo<Highcharts.Options["series"]>(() => {
    const lineWidth = 2
    const dataSeries: Highcharts.Options["series"] = [
      {
        name: "Value",
        type: smooth ? "spline" : "line",
        color: mColors.primary,
        showInLegend: false,
        animation: true,
        connectNulls: true,
        marker: {
          enabled: false,
          fillColor: mColors.primary,
          lineWidth: 1,
          lineColor: "white",
          radius: 2,
        },
        lineWidth,
        states: { hover: { lineWidth } },
        data: data.map((dataPoint) => [
          new Date(dataPoint.datetimeRaw).getTime(),
          dataPoint.value,
        ]),
      },
    ]

    if (thresholds) {
      dataSeries.push({
        name: "Threshold",
        type: "line",
        color: mColors.systemError,
        lineWidth: 1,
        showInLegend: false,
        animation: true,
        connectNulls: false,
        tooltip: { format: "" },
        states: { hover: { enabled: false } },
        marker: { enabled: false },
        enableMouseTracking: false,
        dashStyle: "LongDash",
        data: data.map((dataPoint) => {
          const xVal = new Date(dataPoint.datetimeRaw)
          const quietTimeThreshold = thresholds.find(
            (t) => t.endHHMM && t.startHHMM
          )
          const dayTimeThreshold = thresholds.find((t) => !t.endHHMM)

          const tVal = getActiveNoiseThreshold({
            xVal,
            dayTimeThreshold,
            quietTimeThreshold,
            timezone,
          })
          if (!tVal) return null
          return [xVal.getTime(), tVal]
        }),
      })
    }

    if (yPlotLines) {
      yPlotLines.map((line, index) =>
        dataSeries.push({
          name: "yPlotline" + index,
          type: "line",
          color: mColors.systemError,
          lineWidth: 1,
          showInLegend: false,
          animation: false,
          connectNulls: false,
          tooltip: { format: "" },
          states: { hover: { enabled: false }, inactive: { enabled: false } },
          marker: { enabled: false },
          enableMouseTracking: false,
          dashStyle: "LongDash",
          data: data.map((dataPoint) => {
            const xVal = new Date(dataPoint.datetimeRaw)
            const yVal = line
            return [xVal.getTime(), yVal]
          }),
        })
      )
    }

    if (minMaxIncluded) {
      dataSeries.push({
        name: "Area",
        type: smooth ? "areasplinerange" : "arearange",
        linkedTo: ":previous",
        lineColor: "transparent",
        fillColor: mColors.primary,
        opacity: 0.1,
        connectNulls: false,
        marker: { enabled: false },
        states: { hover: { enabled: false } },
        data: data.map((dataPoint) => [
          new Date(dataPoint.datetimeRaw).getTime(),
          dataPoint.min,
          dataPoint.max,
        ]),
      } as Highcharts.SeriesAreasplinerangeOptions)
    }

    return dataSeries
  }, [data, minMaxIncluded, smooth, thresholds, timezone, yPlotLines])

  const tooltipFormatter = useCallback(
    (context: Highcharts.TooltipFormatterContextObject) => {
      if (!context.x || !context.y) return false

      if (tooltip.formatter) {
        return ReactServer.renderToString(
          tooltip.formatter({
            date: new Date(context.x),
            value: context.y,
            min: context.points?.[1]?.point?.low,
            max: context.points?.[1]?.point?.high,
          })
        )
      }

      const date = formatDate({
        date: new Date(context.x).toISOString(),
        clockType,
        timezone,
      })
      const decimals = tooltip.decimals ?? 1
      const high = context.points?.[1]?.point?.high?.toFixed(decimals)
      const low = context.points?.[1]?.point?.low?.toFixed(decimals)

      const contentLabel = minMaxIncluded
        ? t(langKeys.average)
        : t(langKeys.value)

      // N.B: For some reason adding a naked `:` in the JSX here triggers a
      // weird/invalid Highcharts warning; wrapping it in JS solves it.
      return ReactServer.renderToString(
        <Box>
          <Header>{date}</Header>
          <Content color="tertiary">
            <Row>
              <span>
                <span>{contentLabel + ": "}</span>
                <span>
                  <Value>{context.y.toFixed(decimals)}</Value>
                </span>
                <span>{tooltip.unit}</span>
              </span>
            </Row>
            {minMaxIncluded && (
              <Row>
                <span>
                  <DownArrow>↓</DownArrow>{" "}
                  <Value>{low ? low + tooltip.unit : "-"}</Value>
                </span>
                <span>
                  <UpArrow>↑</UpArrow>{" "}
                  <Value>{high ? high + tooltip.unit : "-"}</Value>
                </span>
              </Row>
            )}
          </Content>
        </Box>
      )
    },
    [clockType, minMaxIncluded, t, timezone, tooltip]
  )

  const options = useMemo<Highcharts.Options>(
    () => ({
      accessibility: { enabled: false }, // Make Highcharts stfu about a11y
      time: {
        timezone,
      },
      chart: {
        zooming: {
          type: zoom,
          pinchType: zoom,
          // TODO WEB-XXX: Configure button theme here
          // https://api.highcharts.com/highcharts/chart.zooming.resetButton
          // resetButton: {theme: {}}
        },
        events: {
          selection(e) {
            if (onZoom) {
              if (e.resetSelection) {
                onZoomReset?.()
                this.xAxis[0]?.setExtremes(
                  this.xAxis[0]?.getExtremes().dataMin,
                  this.xAxis[0]?.getExtremes().dataMax,
                  false
                )
              } else {
                const zMin = e.xAxis[0]?.min
                const zMax = e.xAxis[0]?.max
                zMin && zMax && onZoom(new Date(zMin), new Date(zMax))
                this.showResetZoom()
                return false
              }
            }

            return true
          },
        },
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: canExport,
        csv: {},
      },
      credits: { enabled: false },
      xAxis: {
        type: "datetime",
        lineWidth: 1,
        lineColor: mColors.divider,
        tickColor: mColors.divider,
        crosshair: {
          color: mColors.primary,
          dashStyle: "ShortDot",
          width: 2,
        },
        dateTimeLabelFormats: getXAxisDateTimeLabelFormat(clockType),
        labels: {
          style: {
            fontFamily: "'Figtree', sans-serif",
            fontSize: "12px",
            color: mColors.textTertiary,
          },
        },
        tickAmount: 8,
      },
      yAxis: {
        type: "linear",
        title: {
          text: "",
        },
        endOnTick: true,
        startOnTick: true,
        crosshair: false,
        labels: {
          style: {
            fontFamily: "'Figtree', sans-serif",
            fontSize: "12px",
            color: mColors.textTertiary,
          },
        },
        // Use 10% padding on both sides of the axis:
        minPadding: 0.1,
        maxPadding: 0.1,
        gridLineColor: mColors.divider,
        ...yAxisOptions,
      },
      series,
      tooltip: {
        useHTML: true,
        padding: 0,
        shared: true,
        shadow: false,
        positioner(labelWidth, labelHeight, point) {
          let x = point.plotX + this.chart.plotLeft + 10
          let y = point.plotY + this.chart.plotTop + 10

          if (x + labelWidth >= this.chart.plotWidth) {
            x = point.plotX - labelWidth + this.chart.plotLeft - 10
          }

          if (y + labelHeight >= this.chart.plotHeight) {
            y = point.plotY - labelHeight + this.chart.plotTop - 10
          }

          return {
            x: x,
            y: y,
          }
        },
        formatter() {
          return tooltipFormatter(this)
        },
      },
    }),
    [
      timezone,
      zoom,
      canExport,
      clockType,
      series,
      onZoom,
      onZoomReset,
      tooltipFormatter,
      yAxisOptions,
    ]
  )

  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`

/** https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats */
const defaultDateTimeLabelFormat = {
  millisecond: "%H:%M:%S.%L",
  second: "%H:%M:%S",
  minute: "%H:%M",
  hour: "%H:%M",
  day: "%e %b",
  week: "%e %b",
  month: "%b '%y",
  year: "%Y",
} as const

function getXAxisDateTimeLabelFormat(
  clockType: TUser["clock_type"]
): Highcharts.AxisDateTimeLabelFormatsOptions {
  const use12hClock = clockType === "12"
  if (use12hClock) {
    return {
      ...defaultDateTimeLabelFormat,
      hour: `%I:%M %p`,
      day: "%a %e %b",
    }
  }
  return defaultDateTimeLabelFormat
}
