import { useState } from "react"
import styled from "styled-components"

import { Select } from "@material-ui/core"
import { format } from "date-fns"

import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import {
  TGraphData,
  useGetGraphData,
} from "src/components/Homes/DeviceDetails/Overview/useGetGraphData"
import {
  usePostcsvExportConfirmed,
  usePostcsvExportInitiated,
} from "src/data/analytics/queries/csvExportAnalyticsQueries"
import { useFetchSensorValuesExport } from "src/data/devices/queries/deviceQueries"
import { TDevice, TExportType } from "src/data/devices/types/deviceTypes"
import { THome } from "src/data/homes/types/homeTypes"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { downloadFile } from "src/utils/downloadUtil"

export function ExportDeviceDataDialog({
  open,
  ...rest
}: {
  onClose: () => void
  device: TDevice
  home: THome
  dateRange: IGraphDateRange
  open: boolean
}) {
  if (!open) {
    return null
  }

  return <ExportDeviceDataDialogOpened {...rest} />
}

export function ExportDeviceDataDialogOpened({
  device,
  home,
  dateRange,
  onClose,
}: {
  onClose: () => void
  device: TDevice
  home: THome
  dateRange: IGraphDateRange
}) {
  const { t, langKeys } = useTranslate()

  const [exportType, setExportType] = useState<TExportType>("sound")

  const postcsvExportConfirmed = usePostcsvExportConfirmed()
  const postcsvExportInitiated = usePostcsvExportInitiated()
  useEffectOnce(() => {
    postcsvExportInitiated.mutate()
  })

  const { exportSensorValuesFullResolution } = useFlags()

  const exportSensorValues = useFetchSensorValuesExport({
    orgId: home.owner.id,
    deviceId: device.device_id,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  })

  const { sound, motion, humidity, temperature } = useGetGraphData({
    dateRange,
    device,
    home,
  })

  function handleDownloadClick() {
    postcsvExportConfirmed.mutate({ type: exportType }) // tracking

    // Using the full resolution sensor value export
    // we use a mutation to fetch the data only after the
    // user has clicked on the download button of the dialog
    if (exportSensorValuesFullResolution) {
      return exportSensorValues.mutate(exportType, {
        onSuccess: (data) => {
          return downloadFile(
            // The data coming from the mutation is a string in csv format so there's no need for
            // conversions
            data,
            generateFilename({
              dateRange,
              sensorName: device.description,
              suffix: exportType,
            }),
            "text/csv"
          )
        },
      })
    }

    if (exportType === "sound") {
      if (!sound?.data) {
        throw Error("Trying to export data that is undefined")
      }

      return downloadFile(
        convertSoundDataToCsv(sound.data),
        generateFilename({
          dateRange,
          sensorName: device.description,
          suffix: "sound",
        }),
        "text/csv"
      )
    }

    if (exportType === "temperature") {
      if (!temperature?.data) {
        throw Error("Trying to export data that is undefined")
      }

      return downloadFile(
        convertTemperatureDataToCsv(temperature.data, temperature.unit),
        generateFilename({
          dateRange,
          sensorName: device.description,
          suffix: "temperature",
        }),
        "text/csv"
      )
    }

    if (exportType === "humidity") {
      if (!humidity?.data) {
        throw Error("Trying to export data that is undefined")
      }

      return downloadFile(
        convertHumidityDataToCsv(humidity.data),
        generateFilename({
          dateRange,
          sensorName: device.description,
          suffix: "humidity",
        }),
        "text/csv"
      )
    }
  }

  return (
    <MDialog
      title={t(langKeys.export)}
      open={true}
      onConfirm={handleDownloadClick}
      onClose={onClose}
      confirmLabel="Download"
      loading={
        sound.isLoading ||
        motion.isLoading ||
        humidity.isLoading ||
        temperature.isLoading ||
        exportSensorValues.isLoading
      }
      confirmButtonProps={{ disabled: exportType === "motion" }}
    >
      <Box>
        <div>
          <Title>{t(langKeys.sensor)}</Title>
          <BodyText>{device.description}</BodyText>
        </div>

        <div>
          <Title>
            {t(langKeys.placeholder_web, {
              text: "Export sensor data between",
            })}
          </Title>
          <BodyText>
            {t(langKeys.placeholder_web, {
              text: `Current view (${formatCurrentViewDate(dateRange)})`,
            })}
          </BodyText>
        </div>

        <div>
          <Title>
            {t(langKeys.placeholder_web, {
              text: "Choose sensor data to export",
            })}
          </Title>

          <Select
            value={exportType}
            required
            fullWidth
            native
            onChange={(e) => setExportType(e.target.value as TExportType)}
            disabled={motion.isLoading}
          >
            <option value="sound">{t(langKeys.sound)}</option>
            <option value="temperature">{t(langKeys.temperature)}</option>
            <option value="humidity">{t(langKeys.humidity)}</option>
            {!motion.hidden && (
              <option value="motion">{t(langKeys.motion)}</option>
            )}
          </Select>
        </div>

        <div>
          <Title>
            {t(langKeys.placeholder_web, {
              text: "Export as",
            })}
          </Title>
          <Select value="csv" required fullWidth native>
            <option value="csv">
              {t(langKeys.placeholder_web, {
                text: "CSV file",
              })}
            </option>
          </Select>
        </div>

        <div>
          <InfoBox
            title={t(langKeys.placeholder_web, {
              text: "Beta feature",
            })}
            type="info"
          >
            {t(langKeys.placeholder_web, {
              text: `We're excited to let you try out an early version of the new export
              sensor data feature. We'd love to hear from you on how we could
              improve this feature or about any potential bugs you may find while using it, please use the feedback form below to share your thoughts.`,
            })}
            <div>
              <ExternalLink href="https://forms.gle/4EwhshxXXgM6koUW7">
                {t(langKeys.placeholder_web, {
                  text: "Give feedback",
                })}
              </ExternalLink>
            </div>
          </InfoBox>
        </div>

        {exportType === "motion" && (
          <div>
            <MBanner type="warning" fullWidth size="medium">
              {t(langKeys.placeholder_web, {
                text: "Exporting motion data is not possible to do with this feature yet.",
              })}
            </MBanner>
          </div>
        )}
      </Box>
    </MDialog>
  )
}

function convertSoundDataToCsv(data: TGraphData) {
  const _data = data ?? []

  return [
    "Date, Value, Min, Max, unit",
    ..._data.flat().map((row) => {
      return [
        row.datetime.toISOString(),
        row.value != null ? Math.round(row.value) : "N/A",
        row.min != null ? Math.round(row.min) : "N/A",
        row.max != null ? Math.round(row.max) : "N/A",
        "dB",
      ].join(", ")
    }),
  ].join("\n")
}

function convertTemperatureDataToCsv(data: TGraphData, unit: "C" | "F") {
  const _data = data ?? []

  return [
    "Date, Value, Min, Max, unit",
    ..._data.flat().map((row) => {
      return [
        row.datetime.toISOString(),
        row.value != null ? row.value.toFixed(1) : "N/A",
        row.min != null ? row.min.toFixed(1) : "N/A",
        row.max != null ? row.max.toFixed(1) : "N/A",
        `°${unit}`,
      ].join(", ")
    }),
  ].join("\n")
}

function convertHumidityDataToCsv(data: TGraphData) {
  const _data = data ?? []

  return [
    "Date, Value, Min, Max, unit",
    ..._data.flat().map((row) => {
      return [
        row.datetime.toISOString(),
        row.value != null ? row.value.toFixed(1) : "N/A",
        row.min != null ? row.min.toFixed(1) : "N/A",
        row.max != null ? row.max.toFixed(1) : "N/A",
        `% relative humidity`,
      ].join(", ")
    }),
  ].join("\n")
}

function generateFilename({
  dateRange,
  sensorName,
  suffix,
}: {
  dateRange: IGraphDateRange
  sensorName: TDevice["description"]
  suffix: string
}) {
  // Example return string: Livingroom_2023-12-24_2023-12-25_sound
  return `${sensorName}_${format(
    new Date(dateRange.startDate),
    "yyyy-MM-dd"
  )}_${format(new Date(dateRange.endDate), "yyyy-MM-dd")}_${suffix}`
}

function formatCurrentViewDate(dateRange: IGraphDateRange) {
  return `${format(new Date(dateRange.startDate), "d MMM yyyy")} - ${format(
    new Date(dateRange.endDate),
    "d MMM yyyy"
  )}`
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

function Title({ children }: { children: React.ReactNode }) {
  return (
    <MText variant="bodyS" color="secondary" marginBottom={spacing.XS2}>
      {children}
    </MText>
  )
}

function BodyText({ children }: { children: React.ReactNode }) {
  return <MText variant="bodyS">{children}</MText>
}
