import { Fragment, useMemo } from "react"

import { AxisBottom, AxisLeft } from "@visx/axis"
import { localPoint } from "@visx/event"
import { GridRows } from "@visx/grid"
import { Group } from "@visx/group"
import { scaleBand, scaleLinear, scaleTime } from "@visx/scale"
import { Bar, Line } from "@visx/shape"
import { defaultStyles, TooltipWithBounds, useTooltip } from "@visx/tooltip"
import { fromUnixTime } from "date-fns"

import { useGetUser } from "src/data/user/hooks/useGetUser"
import { TClockTypeMaybe } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors, palette } from "src/ui/colors"
import { Content as TooltipContent } from "src/ui/Graphs/LineGraph/DataPointTooltip"
import {
  localizedTimeFormatter,
  responsiveGraphXTicks,
} from "src/ui/Graphs/utils"
import { extractHHMM, formatDate } from "src/utils/l10n"

const MARGIN_TOP = 60
const MARGIN_LEFT = 30
const MARGIN_VERTICAL = MARGIN_TOP + 60
const MARGIN_HORIZONTAL = MARGIN_LEFT + 30
const BAR_PADDING = 0.2
const DAY_IN_SECONDS = 60 * 60 * 24

// accessors
const getTimeStamp = (d: MotionEventData | undefined) => d?.[0] ?? NaN
const getNbrEvents = (d: MotionEventData | undefined) => d?.[1] ?? NaN

export function MotionBarGraph({
  width,
  height,
  data,
  // TODO WEB-400: See if bucketSize can be used to map incoming data into appropriate
  // 'time buckets' in order to better handle times such as 13:58 which will be
  // currently be rounded up to 14:00
  bucketSize,
}: {
  width: number
  height: number
  data: MotionEventData[]
  bucketSize: number // size of each bucket, in seconds
}) {
  const clockType = useGetUser().clock_type
  const {
    tooltipOpen,
    tooltipLeft = 0,
    tooltipTop = 0,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>()

  // bounds
  const xMax = width - MARGIN_HORIZONTAL
  const yMax = height - MARGIN_VERTICAL
  const xDomain = useMemo(
    () => [
      fromUnixTime(getTimeStamp(data[0])),
      fromUnixTime(getTimeStamp(data[data.length - 1])),
    ],
    [data]
  )

  const maxNumberEvents = useMemo(() => {
    const nbrEvents = data?.map(getNbrEvents) || []
    return Math.max(...nbrEvents)
  }, [data])

  const barWidth = useMemo(
    () =>
      /*
       * This scale is created as a workaround for shoehorning continous data
       * (time) into bars; bars should typically be used to represent ordinal
       * data. We are doing this in order to have d3 calculate the width of the
       * bars for us.
       */
      scaleBand({
        range: [0, xMax],
        domain: data.map((d) => String(getTimeStamp(d))),
        padding: BAR_PADDING,
      }).bandwidth(),
    [xMax, data]
  )

  const barWidthNoGap = useMemo(
    () =>
      /*
       * This scale is created as a workaround for shoehorning continous data
       * (time) into bars; bars should typically be used to represent ordinal
       * data. We are doing this in order to have d3 calculate the width of the
       * bars for us.
       */
      scaleBand({
        range: [0, xMax],
        domain: data.map((d) => String(getTimeStamp(d))),
        padding: 0,
      }).bandwidth(),
    [xMax, data]
  )

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        domain: xDomain,
      }),
    [xMax, xDomain]
  )

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: [0, maxNumberEvents],
      }),
    [yMax, maxNumberEvents]
  )

  function handleTooltip(
    tooltipData: TooltipData,
    ev: React.MouseEvent | React.TouchEvent
  ) {
    const tooltipTop = localPoint(ev)?.y ?? 0
    const tooltipLeft = tooltipData.x + tooltipData.width
    showTooltip({ tooltipData, tooltipTop, tooltipLeft })
  }

  const barData = useMemo(() => {
    return data.map((d, index) => {
      const unixTime = getTimeStamp(d)
      const events = getNbrEvents(d)
      const barHeight = events ? yMax - yScale(events) : 0
      const barX = xScale(fromUnixTime(unixTime))
      const barY = yMax - barHeight

      const tooltip: TooltipData = {
        id: unixTime,
        x: barX + barWidth / 2 /* => center of bar */ + MARGIN_LEFT,
        y: barY + MARGIN_TOP,
        data: {
          nbrEvents: events,
          unixTime: unixTime,
        },
        height: barHeight,
        width: barWidth,
        index,
      }

      return {
        barX,
        barY,
        barHeight,
        tooltip,
      }
    })
  }, [barWidth, data, yMax, xScale, yScale])

  return (
    <div style={{ position: "relative" }}>
      <svg width={width} height={height}>
        <rect width={width} height={height} fill="white" rx={14} />

        <Group
          top={MARGIN_TOP}
          left={MARGIN_LEFT}
          width={width - MARGIN_HORIZONTAL}
        >
          <Group>
            {barData.map((bar, index) => {
              const onMouseLeave = () => hideTooltip()
              const onMouseMove = (ev: React.MouseEvent | React.TouchEvent) =>
                handleTooltip(bar.tooltip, ev)

              return (
                <Fragment key={`bars-${index}`}>
                  <Bar
                    key={`invisibar-${index}`}
                    x={bar.barX}
                    y={0}
                    width={barWidthNoGap}
                    height={yMax}
                    fill={`${palette.hav}00`}
                    onMouseLeave={onMouseLeave}
                    onMouseMove={onMouseMove}
                    onTouchStart={onMouseMove}
                    onTouchMove={onMouseMove}
                  />
                  <Bar
                    key={`bar-${index}`}
                    x={bar.barX}
                    y={bar.barY}
                    width={barWidth}
                    height={bar.barHeight}
                    fill={mColors.primary}
                    onMouseLeave={onMouseLeave}
                    onMouseMove={onMouseMove}
                    onTouchStart={onMouseMove}
                    onTouchMove={onMouseMove}
                  />
                </Fragment>
              )
            })}
          </Group>

          <AxisBottom
            top={yMax}
            scale={xScale}
            tickFormat={localizedTimeFormatter(clockType)}
            numTicks={responsiveGraphXTicks(width)}
            stroke="rgba(0,0,0,0.2)"
            tickStroke="rgba(0,0,0,0.2)"
            strokeWidth={1}
            tickLabelProps={() => ({
              fill: mColors.textTertiary,
              textAnchor: "middle",
              fontSize: 12,
              fontFamily: "Figtree",
              dx: "-0.25em",
              dy: "0.25em",
            })}
          />

          <GridRows
            top={0}
            left={0}
            width={xMax}
            scale={yScale}
            numTicks={nbrYTicks(maxNumberEvents)}
            stroke="rgba(0,0,0,0.1)"
          />
        </Group>

        <Group>
          <AxisLeft
            scale={yScale}
            numTicks={nbrYTicks(maxNumberEvents)}
            tickFormat={(v) => Math.round(v as number).toString()}
            top={MARGIN_TOP}
            left={MARGIN_LEFT + 4}
            stroke="rgba(0,0,0,0.2)"
            hideAxisLine={true}
            hideTicks={true}
            hideZero={true}
            tickLabelProps={() => {
              return {
                fill: mColors.textTertiary,
                textAnchor: "end",
                fontSize: 12,
                fontFamily: "Figtree",
                dx: "-0.25em",
                dy: "0.25em",
              }
            }}
          />

          {tooltipOpen && tooltipData && (
            <>
              <Line
                from={{ x: tooltipData.x, y: MARGIN_TOP - 10 }}
                to={{ x: tooltipData.x, y: height - MARGIN_TOP / 2 }}
                stroke={mColors.primary}
                strokeWidth={2}
                style={{ pointerEvents: "none" }}
                strokeDasharray="2,2"
              />

              <circle
                cx={tooltipData.x}
                cy={tooltipData.y}
                r={10}
                fill={mColors.primary}
                opacity={0.3}
                strokeWidth={0}
                style={{ pointerEvents: "none" }}
              />

              <circle
                cx={tooltipData.x}
                cy={tooltipData.y}
                r={4}
                fill={mColors.primary}
                stroke={"white"}
                strokeWidth={2}
                style={{ pointerEvents: "none" }}
              />
            </>
          )}
        </Group>
      </svg>

      {tooltipOpen && tooltipData && (
        <MotionBarTooltip
          bucketSize={bucketSize}
          clockType={clockType}
          tooltipData={tooltipData}
          tooltipLeft={tooltipLeft}
          tooltipTop={tooltipTop}
        />
      )}
    </div>
  )
}

function MotionBarTooltip({
  bucketSize,
  tooltipData,
  clockType,
  tooltipTop,
  tooltipLeft,
}: {
  bucketSize: number
  tooltipData: TooltipData
  tooltipTop: number
  tooltipLeft: number
  clockType: TClockTypeMaybe
}) {
  const { t, langKeys } = useTranslate()

  // This unixTime is already in the timezone of the home
  const unixTime = tooltipData.data.unixTime
  const timeNext = unixTime + bucketSize
  const dateNext = fromUnixTime(timeNext)

  const localTime = formatDate({
    date: fromUnixTime(unixTime).toISOString(),
    clockType,
  })

  const nbrEvents = tooltipData.data.nbrEvents
  const events =
    nbrEvents === 1
      ? t(langKeys.device_detail_graph_single_motion_event)
      : t(langKeys.device_detail_graph_multiple_motion_events, {
          0: nbrEvents,
        })

  // This time used below is already in the timezone of the home
  const dateStr =
    bucketSize < DAY_IN_SECONDS
      ? extractHHMM(dateNext, clockType)
      : formatDate({ date: dateNext.toISOString(), clockType })

  return (
    <div>
      <TooltipWithBounds
        key={`tooltip-${tooltipData.id}`}
        top={tooltipTop}
        left={tooltipLeft}
        style={{
          ...defaultStyles,
        }}
      >
        <TooltipContent>
          <div>{events}</div>
          <small>
            {localTime} - {dateStr}
          </small>
        </TooltipContent>
      </TooltipWithBounds>
    </div>
  )
}
interface TooltipData {
  id: string | number
  index: number
  height: number
  width: number
  x: number
  y: number
  data: {
    nbrEvents: number
    unixTime: number
  }
}

type MotionEventData = [
  number /* unix time in seconds */,
  number /* number of events */,
]

function nbrYTicks(maxNbr: number): number {
  if (maxNbr < 10) {
    return maxNbr
  }
  if (maxNbr < 20) {
    return maxNbr / 2
  }
  if (maxNbr < 100) {
    return maxNbr / 10
  }
  return 3
}
